<template>
  <el-form
    ref="form"
    v-loading="loading"
    :model="model"
    :rules="rules"
    label-position="top"
    style="max-width: 700px;"
  >
    <slot :model="model"></slot>

    <portal to="fixed-footer-left">
      <el-button type="primary" :loading="saving" @click="checkForm">
        {{ submitLabel }}
      </el-button>
      <el-button @click="$router.go(-1)">
        Abbrechen
      </el-button>
    </portal>
  </el-form>
</template>

<script>
import FixedFooterMixin from "@/mixins/fixed_footer"
import FormMixin from "@/mixins/form.js"
import MessagesMixin from "@/mixins/messages"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import utils from "@/utils"

export default {
  mixins: [FormMixin, MessagesMixin, FixedFooterMixin, ApiErrorHandlerMixin],
  props: {
    model: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      required: true
    },
    rules: {
      type: Object,
      required: true
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    modelName: {
      type: String,
      default: "Eintrag"
    },
    additionalValidators: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      saving: false
    }
  },
  mounted() {
    utils.addErrorBreadcrumb({
      category: "UI", // e.g. "UI", "Network", "Navigation", "Console"
      action: "Opened Form", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
      metadata: {
        mode: this.mode,
        model_name: this.modelName,
        path: this.$route.path
      } // key/value metadata in <string, string> format
    })
  },
  methods: {
    checkForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let validatorFailed = false

          this.additionalValidators.forEach(function(validator) {
            if (validatorFailed === false) {
              if (validator.validator() === false) {
                validator.onError()
                validatorFailed = true
              }
            }
          })

          if (validatorFailed === false) {
            this.submitForm()
          }
        } else {
          this.showFormErrors()
          utils.addErrorBreadcrumb({
            category: "UI", // e.g. "UI", "Network", "Navigation", "Console"
            action: "Had Validation Error(s)", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
            metadata: {
              mode: this.mode,
              model_name: this.modelName,
              path: this.$route.path
            } // key/value metadata in <string, string> format
          })
        }
      })
    },
    async submitForm() {
      this.saving = true

      try {
        await this.formSubmitted(this.model)
        this.saving = false
        if (this.mode === "new") {
          this.showFormCreateSuccessMessage(this.modelName)
        } else {
          this.showFormUpdateSuccessMessage()
        }
      } catch (error) {
        this.saving = false
        if (error.message === "Save cancelled") {
          // Do nothing
        } else {
          this.handleApiError(error)
        }
      }
    }
  }
}
</script>
